import React, { useState, useEffect } from "react"
import Title from "./Title"
import { Link } from "gatsby"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import EventImg from "../assets/1fortylive2.jpg"
import DogImg from "../assets/finn-forest.jpg"
import SprayImg from "../assets/heliocare-spray.jpg"


export const Slider = ({title,showLink}) => {
  //const handleDragStart = (e) => e.preventDefault();

  const [, setTimestamp] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const onLoad = () => setTimestamp(Date.now());
  const onSlideChanged = ({ item }) => setActiveIndex(item);

  const items = [
    <LazyLoader onLoad={onLoad} src={SprayImg} delay={2500}  alt={"Heliocare 360 invisible spray"}/>,
    <LazyLoader onLoad={onLoad} src={EventImg} delay={2000}  alt={"Killa P performing at 1Forty music event"}/>,
    <LazyLoader onLoad={onLoad} src={DogImg} delay={1000}  alt={"Springer spaniel in a forest"}/>
  ];
    return (
      <section className="section">
        <Title title={title} />
        <AliceCarousel 
          mouseTracking items={items} 
          infinite="true" 
          disableButtonsControls="true"  
          activeIndex={activeIndex} 
          onSlideChanged={onSlideChanged} 
        />
        {showLink && (
        <Link to="/gallery" className="btn center-btn">Gallery</Link>
      )}
      </section>
    )
  }


  function LazyLoader(props) {
    let timerId;
    const { src = '', delay = 0, onLoad, alt = '' } = props;
    const [isMounted, setMounted] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const handleDragStart = (e) => e.preventDefault();

    function loadImage() {
        const image = new Image();

        image.src = src;
        image.alt = alt;
        image.onload = () => {
            setLoading(false);
            onLoad();
            
        };
    }

    useEffect(() => {
        if (!isMounted) {
            setMounted(true);
            delay ? (timerId = setTimeout(loadImage, delay)) : loadImage();
        }
        return () => clearTimeout(timerId);
    }, []);

    return isLoading ? <div>Loading...</div> : <img src={src} onDragStart={handleDragStart} alt={alt}/>;
}
  export default Slider