import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"
import { FaGithubSquare, FaShareSquare } from "react-icons/fa"
import { Link } from "gatsby"
const Project = ({description, title, github, stack, url, image, index, slug, id}) => {
  return (
   <Link to={`/projects/${slug}`} key={id} className="project">
      
      <Image fluid={image.childImageSharp.fluid} className="project-img" />
      <div className="project-info">
        
        <h3>{title}</h3>
        <p className="project-desc">{description}</p>
        <div className="project-stack">
          {stack.map((item) => {
            return (
              <span key={item.id}>{item.title}</span>
            )
          })}
        </div>
        <div className="project-links">
        {
          github && (
            <a href={github}>
              <FaGithubSquare className="project-icon" />
            </a>
          )
        }
        {
          url && (
            <a href={url}>
              <FaShareSquare className="project-icon" />
            </a>
          )
        }
        </div>
      </div>
    
    </Link>
    
  )
}

Project.propTypes = {}

export default Project
