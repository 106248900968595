import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import SocialLinks from "../constants/socialLinks"

const query = graphql`
{
  file(relativePath: {eq: "hero-img-3.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`
const Hero = () => {
  const {
    file: {
      childImageSharp: {fluid},
    },
  } = useStaticQuery(query);
  

  return <header className="hero">
    <div className="section-center hero-center">
      <article className="hero-info">
        <div>
          
          <h1>Alright? I'm Cal.</h1>
          <h4>Web Development & Photography</h4>
          <Link to='/contact' className="btn">Contact Me</Link>
          <SocialLinks />          
        </div>
      </article>
      <Image fluid={fluid} className="hero-img" objectFit="contain"/>
    </div>
  </header>
}

export default Hero
